* {
    box-sizing: border-box;
}
.navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}
.outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.outer-register {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

h2 {
    color: white;
  }

.inner {
    /* display: flex;
    flex-direction: column; */
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    background: rgba(77, 77, 77, 0.31);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    padding: 45px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
    text-align: center;
    justify-content: center;
    /* z-index: 1; */
    position: relative;
  }
  .middle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    position: relative;
  }
  
  .outer .form-control:focus {
    border-color: #c9302c;
    box-shadow: none;
  }
  
  .outer h2 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
    color: white;
  }
  
  h2.headertekst {
    text-align: center;
  }
  
  .custom-control-label {
    font-weight: 400;
  }
  
  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
  
  .forgot-password a {
    color: #ffffff;
  }
  
  .btn {
    width: 100%;
  }
  
  label {
    color: white;
  }
  
  p {
    color: #ffffff;
  }
  
  .forgot-password {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #acacac;
    margin: 0;
  }
  
  .forgot-password a {
    color: rgb(255, 255, 255);
  }
  
  .dropdown-toggle {
    white-space: nowrap;
    background-color: white !important;
    color: #ff0800 
  }
  
  .dropdown-menu.show {
    display: block;
    width: 100%;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    border: 0;
  }
  
  .SSO-Google,
  .SSO-Facebook {
    width: 100%;
    height: 50px;
    font-size: medium;
    outline: none;
    border: none;
  }
  
  .SSO-Google:hover {
    background-color: #756e6e;
  }
  
  .SSO-Facebook {
    background-color: #3b5998;
    color: #ffffff;
  }
  
  .SSO-Facebook:hover {
    background-color: #4c70be;
  }
  
  .social {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    /* margin-top: 10px; */
  }
  
  .dropdown-item:checked {
    background-color: red !important;
  }
  
  @media screen and (max-width: 480px) {
    .inner {
      width: 90%;
    }
  
    .SSO-Google,
    .SSO-Facebook {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 280px) {
    .inner {
      margin: auto !important;
      width: 100% !important;
    }
  
    .SSO-Google,
    .SSO-Facebook {
      margin-left: -18px;
      width: 120%;
      font-size: 12px;
    }
  
    .social {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }