.vehiOuter{

margin: 30px 0 0 0;
justify-content: center;
display: flex;
}

.topics{
margin-left: 300px;
}


/* body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  background-repeat: no-repeat, repeat;
  background-attachment: fixed;
  background-size: cover;
} */

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.carousel-item img {
  border-radius: 5px !important;
  width: 100%;
  /* height: 500px; */
}

.container {
  width: 100% !important;
  height: auto;
}

.container {
  width: 100% !important;
}

.column {
  float: left;
}

.left {
  width: 55%;
}

.right {
  width: 55%;
}


.btn1 {
  margin-bottom: 30px;
  color: brown;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.failed {
  color: #ff0000;
  text-align: center;
  width:40%;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;
}
.unavailable {
  color: #808080;
  text-align: center;
  width:40%;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;

}

.passed {
  color: #2a8d4f;
  background-color: '#F6F5F4';
  text-align: center;
  width:40%;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;

}
.detail-content{
  width: 60%;
}
.det_head {
  /* margin-bottom: 0.5px; */
  margin-bottom: 0;
  margin-top: 10px;
}

.details_fc {
  color: #00008B;
  /* padding-bottom: 5px; */
}

/* rating part css */
.info_logo {
  width: 15px;
  height: 15px;
  padding: 1px;
  filter: invert(10%) sepia(40%) saturate(6358%) hue-rotate(232deg) brightness(114%) contrast(129%);
  cursor: pointer;
}
.rating_det {
  font-size: 12px;
}
.Rating{
  display: flex;
}
.Rating_Star {
  width: 20px;
  height: 20px;
  filter: invert(10%) sepia(40%) saturate(6358%) hue-rotate(232deg) brightness(114%) contrast(129%);
  padding: 2px;
  
}
.rating_para {
  color: #000000;
  font-size: 12px;
}
.rating_h{
  font-size: 14px;
}
.no_rating{
  padding-bottom: 20px;
}
.rating_h1{
  color: #00008B;
  margin-top: 20px;
  
}
                        
.rating_clean_car{
  margin-top: 15px;
  padding-top: 15px;
  border: 1px solid f0f6fb;
  background-color: #f0f6fb;
  border-radius: 12px;
  display: flex;
  /* height: 80px; */
  padding-bottom: 15px;
}

.rating_wrong_img{
  width: 20px;
  height: 20px;
  margin: 10px;
  display: center;
  filter: invert(15%) sepia(98%) saturate(6831%) hue-rotate(359deg) brightness(103%) contrast(108%);
}

.rating_correct_img{
  width: 20px;
  height: 20px;
  margin: 10px;
  display: center;
  filter: invert(61%) sepia(75%) saturate(525%) hue-rotate(71deg) brightness(92%) contrast(89%);}

.rating_clean_car_para{
  margin-top: 8px;
}
.background-row {
  margin-bottom: 20px;
}
.vehi-data{
  padding-top: 100px;
  padding-bottom: 100px;
}
.vehi-detail {
  background-color: #b1ebff;
  color: rgb(27, 37, 151);
  border-radius: 5px;
}

.vehi-detail-row {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 1281px){
  .carousel-item img {
      width: 100%;
      max-height: 800px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px)  {

  .carousel-item img {
    width: 100%;
    max-height: 800px;
}
}
@media (min-width: 768px) and (max-width: 1024px) {
  .carousel-item img {
    max-width: 100%;
    max-height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  .carousel-item img {
    max-width: 100%;
    max-height: 500px;
  }
  
}
@media (min-width: 481px) and (max-width: 767px){
  .carousel-item img {
    max-width: 100%;
    max-height: 300px;
  }
  .background-row {
    margin-bottom: 25px;
  }
  .vehicle-table {
    padding-left: 20px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .carousel-item img {
    max-width: 100%;
    max-height: 300px;
    
  }
  .background-row {
    margin-bottom: 20px;
  }
  .vehicle-table {
    padding-left: 20px;
  }
}
/* @media screen and (max-width: 768px) {
 
  .carousel-item img {
    max-width: 100%;
    max-height: 500px;
  }
} */

@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
  .row {
    column-gap: 0.1rem;
  }
  
}
.popover-text{
  color:#000000;
  text-align: center;
  padding: 10px;
  margin-bottom: 0px;
  font-size: 12px;
}