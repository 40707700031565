.row {
    width: 100%;
    /* height: 50px; */
    margin: 0;
    color: rgb(95, 115, 116);
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.container-fluid {
    margin-top: 20px;
}