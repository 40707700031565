.side {
  width: 16%;
  background-color: white; /* White background */
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-top-right-radius: 15px; /* Rounded corner on the top-right */
  border-bottom-right-radius: 15px; /* Rounded corner on the bottom-right */
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.15); /* Soft shadow on the sidebar */
  padding-top: 20px;
  margin: 0;
}

.side .row {
  width: calc(100% - 10px); /* Full width minus padding */
  height: 50px;
  margin: 0;
  color: black; /* Black text color */
  font-weight: 400;
  display: flex;
  cursor: pointer; /* Pointer cursor on hover */
  align-items: center; 
  justify-content: flex-start;
  padding-left: 20px;
  border-radius: 0 10px 10px 0; /* Right-side corner radius */
  transition: background-color 0.3s, transform 0.2s;
}

.side .row:hover {
  background-color: #e0dfdf; /* Light hover effect */
  transform: translateX(5px); /* Slight shift on hover for modern feel */
}

.signout {
  padding-right: 10px;
  text-align: center;
}

.signout .btn {
  /* background-color: black;  */
  margin-top: 20px;
  color: white;
  font-weight: bold;
  padding: 10px 10px;
  border-radius: 0 8px 8px 0; /* Right-side rounded corners */
  border: none;
  transition: background-color 0.3s, transform 0.2s;
}

.signout .btn:hover {
  /* background-color: #100f0f; */
  transform: translateX(3px);
}
