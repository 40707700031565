/* styles.css */
#upload {
  opacity: 0;
}

#upload-label {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

.image-area {
  border: 2px dashed rgba(255, 255, 255, 0.7);
  padding: 1rem;
  position: relative;
}

.image-area::before {
  content: 'Uploaded image result';
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  z-index: 1;
}

.image-area img {
  z-index: 2;
  position: relative;
}

body {
  min-height: 100vh;
}

.imgupload {
  margin: 20px;
  display: flex;
  justify-content: flex-end !important;
  width: 300px;
}

.imgupload-container {
  display: flex;
  justify-content: flex-end !important;
}

h1 {
  color: black;
}